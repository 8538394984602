<template>
  <div class="container">Something about about</div>
</template>

<script>
export default {
  name: 'About'
}
</script>

<style>

</style>