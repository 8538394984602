<template>
  <!-- Start Header Area -->
    <header class="header index4">
        <div class="navbar-area">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-12">
                        <nav class="navbar navbar-expand-lg">
                            <a class="navbar-brand logo text-dark" href="/">
                                <img style="max-width: 50px" src="assets/images/logo/logo.png" alt="Logo" />
                            </a>
                            <button class="navbar-toggler" type="button" data-toggle="collapse"
                                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false" aria-label="Toggle navigation">
                                <span class="toggler-icon"></span>
                                <span class="toggler-icon"></span>
                                <span class="toggler-icon"></span>
                            </button>

                            <div class="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                                <ul id="nav" class="navbar-nav ml-auto">
                                    <li class="nav-item">
                                        <a class="" href="#hero-area">Home</a>
                                    </li>
                                    <li class="nav-item"><a href="#services">We Offer</a>
                                        <!-- <ul class="sub-menu">
                                            <li><a href="services.html">Investing & Trading</a></li>
                                            <li><a href="service-single.html">Financial Planning</a></li>
                                            <li><a href="service-single.html">Community</a></li>
                                        </ul> -->
                                    </li>
                                    <li class="nav-item">
                                        <a href="#about-me">About me</a>
                                    </li>
                                    <!-- <li class="nav-item"><a href="#portfolio">Portfolio</a></li> -->
                                    <li class="nav-item"><a href="#pricing">Pricing</a></li>
                                    <li class="nav-item"><a href="mailto:contact@bantuinvestments.com?subject=Bantu Investments Website: [Add your subject here]">Contact</a></li>
                                </ul>
                            </div>
                        </nav>
                        <!-- navbar -->

                    </div>
                </div>
                <!-- row -->
            </div>
            <!-- container -->
        </div>
        <!-- navbar area -->
    </header>
    <!-- End Header Area -->
</template>

<script>
export default {
    name: 'navComponent'
}
</script>

<style>

</style>