<template>
  <!-- Start Hero Area -->
    <section class="hero-area index4">
        <!-- Single Slider -->
        <div class="hero-inner">
            <div class="container">
                <div class="row ">
                    <div class="col-lg-6 co-12">
                        <div class="home-slider">
                            <div class="hero-text">
                                <h5 class="small wow fadeInUp" data-wow-delay=".3s">Hello There...</h5>
                                <h1 class="wow fadeInUp" data-wow-delay=".5s"><span>I am</span> Jefferson</h1>
                                <ul class="wow fadeInUp" data-wow-delay=".7s">
                                    <li>Software Developer</li>
                                    <li>Investor</li>
                                    <li>Entrepreneur</li>
                                </ul>
                                <p class="wow fadeInUp" data-wow-delay=".9s">I am the founder of <strong>Bantu Investments.</strong> Thank you for checking out our website.
                                <br>I am on a mission to build a community of like minded individuals who whish to take control of their <strong>financial futures</strong> and embark on a <strong>personal growth journey.</strong></p>
                                <div class="button wow fadeInUp" data-wow-delay="1.1s">
                                    <a href="https://launchpass.com/choplife-gang/member" class="btn">Start your 1 week free trial</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-12">
                        <div class="personal-image wow fadeInRight" data-wow-delay=".5s">
                            <img src="/assets/images/hero/phone.png" style="max-width: 80%; min-height: 100%;" alt="#">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--/ End Single Slider -->
    </section>
    <!--/ End Hero Area -->
</template>

<script>
export default {
    name: 'mainHeroComponent'
}
</script>

<style>
    section.hero-area {
        text-align: left;
    }
</style>