<template>
  <!-- Start About Us Area -->
    <section id="about-me" class="about-us index-4 section">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="about-left">
                        <div class="section-title align-left">
                            <span class="wow fadeInDown" data-wow-delay=".2s">my story</span>
                            <h2 class="wow fadeInUp" data-wow-delay=".4s">About Me</h2>
                            <p class="wow fadeInUp" data-wow-delay=".6s">"Nothing worthwhile in life comes without sacrifice" <small>--Some human being</small></p>
                        </div>
                        <div class="about-tab wow fadeInUp" data-wow-delay=".5s">
                            <!-- Nav Tab  -->
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item"><a class="nav-link active" data-toggle="tab" href="#t-tab1"
                                        role="tab">About Me</a></li>
                                <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#t-tab2"
                                        role="tab">Experience</a></li>
                                <li class="nav-item"><a class="nav-link" data-toggle="tab" href="#t-tab3"
                                        role="tab">Education</a></li>
                            </ul>
                            <!--/ End Nav Tab -->
                            <div class="tab-content text-left" id="myTabContent">
                                <!-- Tab 1 -->
                                <div class="tab-pane fade show active" id="t-tab1" role="tabpanel">
                                    <div class="tab-content">
                                        <p>I am a software developer, investor and entrepreneur. I started programming nearly 7 years ago and it has opened so many doors for me. I started my first business, a web developement business, at the age of 17. Since then I have made my way into the investment world through the stock market. I made my very first investment in the stock market 4 years ago. Aside from Bantu Group LLC, parent to Bantu Investments, I am also the Cheif Technology Officer of Ukuaji and <a href="http://www.scholaclub.com">Scholaclub Inc.</a></p>
                                        <div class="row">
                                            <div class="col-lg-6 col-12">
                                                <ul>
                                                    <li><span>Name : </span>Jefferson Ondze Mangha</li>
                                                    <li><span>Age : </span>23 Years</li>
                                                </ul>
                                            </div>
                                            <div class="col-lg-6 col-12">
                                                <ul>
                                                    <li><span>City :</span> Irving, TX</li>
                                                    <li><span>Phone :</span>(682)221-3536</li>
                                                    <li><span>Email : </span>jefferson@bantuinvestments.com</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--/ End Tab 1 -->
                                <!-- Tab 2 -->
                                <div class="tab-pane fade" id="t-tab2" role="tabpanel">
                                    <div class="tab-content">
                                        <h3>Founder <span> 2021 - Present, Bantu Group LLC | Texas</span></h3>
                                        <p>Leading the Bantu community of investors and entrepreneurs.</p>
                                        <h3>CTO <span> 2019 - Present, Ukuaji | Texas</span></h3>
                                        <p>Head of developement for the Ukuaji platform, an online investment platform to facilitate the infusion of funds into startups and entrepreneurs in sub-sahara Africa</p>
                                        <h3>PHP Developer <span> 2018 - Present, L5 LLC | Texas</span></h3>
                                        <p>One of three codebusters. We build and maintain the level5 analytics platform for <a href="https://www.energyby5.com">5</a></p>
                                    </div>
                                </div>
                                <!--/ End Tab 2 -->
                                <!-- Tab 3 -->
                                <div class="tab-pane fade" id="t-tab3" role="tabpanel">
                                    <div class="tab-content">
                                        <h3>University of Texas, Arlington <span> 2020 - Present, MBA | MSIS | Texas</span></h3>
                                        <p>I am currently working on my Masters of Business Administration as well as my Masters of Science in Information Systems at UT Arlington.</p>
                                        <h3>University of Texas, Arlington <span> 2014 - 2019, Bachelor Degree | Texas</span></h3>
                                        <p>I received my BA in Communications, Communication Technology at UT Arlington.</p>
                                    </div>
                                </div>
                                <!--/ End Tab 3 -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-12 d-none">
                    <div class="about-right wow fadeInRight" data-wow-delay=".5s">
                        <img src="https://via.placeholder.com/520x540" alt="#">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- /End About Us Area -->
</template>

<script>
export default {
    name: 'aboutComponent'
}
</script>

<style>

</style>