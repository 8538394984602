<template>
  <!-- Start Footer Area -->
    <footer class="footer text-left">
        <!-- Start Middle Top -->
        <div class="footer-middle">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3 col-md-6 col-12">
                        <!-- Single Widget -->
                        <div class="f-about single-footer">
                            <div class="logo">
                                <a href="index.html"><img src="assets/images/logo/logo.png" alt="Logo"></a>
                            </div>
                            <p>Join the people's investments!</p>
                            <!-- <div class="footer-social">
                                <ul>
                                    <li><a href="#"><i class="lni lni-instagram"></i></a></li>
                                    <li><a href="#"><i class="lni lni-twitter"></i></a></li>
                                    <li><a href="#"><i class="lni lni-linkedin"></i></a></li>
                                </ul>
                            </div> -->
                        </div>
                        <!-- End Single Widget -->
                    </div>
                    <div class="col-lg-3 col-md-6 col-12">
                        <!-- Single Widget -->
                        <div class="single-footer f-link">
                            <h3>Navigate</h3>
                            <ul>
                                <li><a href="#about-me">About Me</a></li>
                                <li><a href="#services">Our Services</a></li>
                                <li><a href="mailto:contact@bantuinvestments.com?subject=Bantu Investments Website: [Add your subject here]">Contact Us</a></li>
                                <li><a href="#">Our Blog</a></li>
                            </ul>
                        </div>
                        <!-- End Single Widget -->
                    </div>
                    <div class="col-lg-3 col-md-6 col-12">
                        <!-- Single Widget -->
                        <div class="single-footer f-link">
                            <h3>Social</h3>
                            <!-- <ul>
                                <li><a href="#">Topics</a></li>
                                <li><a href="#">Terms of Use</a></li>
                                <li><a href="#">Privacy Policy</a></li>
                                <li><a href="#">License</a></li>
                            </ul> -->
                            <ul>
                                <li> <a href="#">Coming soon...</a></li>
                            </ul>
                        </div>
                        <!-- End Single Widget -->
                    </div>
                </div>
            </div>
        </div>
        <!--/ End Footer Middle -->
        <!-- Start Footer Bottom -->
        <div class="footer-bottom">
            <div class="container">
                <div class="inner">
                    <div class="row">
                        <div class="col-12">
                            <div class="left">
                                <p>Bantu Investments &copy; All Rights Reserved</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Footer Middle -->
    </footer>
    <!--/ End Footer Area -->
</template>

<script>
export default {
    name: 'footerComponent'
}
</script>

<style>

</style>