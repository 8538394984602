<template>
  <!-- Start Faq Area -->
    <section class="faq-area">
        <div class="container text-left">
            <div class="row pt-5 mt-5">
                <div class="col-12 col-md-6">

                    <!-- Item -->
                    <div class="d-flex single-faq wow fadeInUp" data-wow-delay=".2s">

                        <!-- Badge -->
                        <div class="cercle">
                            <span>?</span>
                        </div>

                        <div class="content">

                            <!-- Heading -->
                            <h4 class="heading">
                                Can I cancel my membership?
                            </h4>

                            <!-- Text -->
                            <p class="text">
                                Absolutely. All monthly membership can be cancelled at any time. Our yearly memberships can be cancelled for a refund of the remaining unused terms.
                            </p>

                        </div>

                    </div>

                    <!-- Item -->
                    <div class="d-flex single-faq wow fadeInUp" data-wow-delay=".4s">

                        <!-- Badge -->
                        <div class="cercle">
                            <span>?</span>
                        </div>

                        <div class="content">

                            <!-- Heading -->
                            <h4 class="heading">
                                Can I share what I learn in this group with others not in this group?
                            </h4>

                            <!-- Text -->
                            <p class="text">
                                Kind of, all educational material such as video lessons, trade and investment signals are for members only and should not be shared without prior written consent. However, the knowledge you gain from our material is yours to use however you see fit.
                            </p>

                        </div>

                    </div>

                </div>
                <div class="col-12 col-md-6">

                    <!-- Item -->
                    <div class="d-flex single-faq wow fadeInUp" data-wow-delay=".2s">

                        <!-- Badge -->
                        <div class="cercle">
                            <span>?</span>
                        </div>

                        <div class="content">

                            <!-- Heading -->
                            <h4 class="heading">
                                Are there 1-on-1 or small group sessions?
                            </h4>

                            <!-- Text -->
                            <p class="text">
                                Yup! 1-on-1 and small group sessions will be available based on my availability and are on a first come first serve basis.
                            </p>

                        </div>

                    </div>

                    <!-- Item -->
                    <div class="d-flex single-faq wow fadeInUp" data-wow-delay=".4s">

                        <!-- Badge -->
                        <div class="cercle">
                            <span>?</span>
                        </div>

                        <div class="content">

                            <!-- Heading -->
                            <h4 class="heading">
                                Can I sue you guys if I lose money?
                            </h4>

                            <!-- Text -->
                            <p class="text">
                                You CANNOT, investing & trading carries inherent risk, our group exists for the sole purpose of educating the members about the markets and how to navigate finances. I provide my investments and trading signals as a bonus for those who are interested. We cannot be held responsible for the loss and/or gains of our members funds unless otherwise stated in a written agreement between the member and myself.
                            </p>

                        </div>

                    </div>

                </div>
            </div> <!-- / .row -->
        </div> <!-- / .container -->
    </section>
    <!-- End Faq Area -->
</template>

<script>
export default {
    name: 'fagComponent'
}
</script>

<style>

</style>