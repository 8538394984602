<template>
  <!-- Pricing Table -->
    <section id="pricing" class="pricing-table section">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="section-title">
                        <span class="wow fadeInDown" data-wow-delay=".2s">LEARNING SHOULD BE AFFORDABLE</span>
                        <h2 class="wow fadeInUp" data-wow-delay=".4s">The Pricing Plan</h2>
                        <p class="wow fadeInUp" data-wow-delay=".6s">One affordable low price</p>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-12">
                    <!-- Single Table -->
                    <div class="single-table wow fadeInUp" data-wow-delay=".4s">
                        <!-- Table Head -->
                        <div class="table-head">
                            <h4 class="title">Monthly subscription</h4>
                            <!-- <h6 class="text-primary">Use code <strong>FIRST100</strong> to get 50% off the first year</h6> -->
                            <div class="price">
                                <p class="amount">$39.99<span class="duration">per month</span></p>
                            </div>
                        </div>
                        <!-- End Table Head -->
                        <!-- Table List -->
                        <ul class="table-list">
                            <li>Weekly Q&A sessions</li>
                            <li>Access to all community features</li>
                            <li>Weekly newsletter</li>
                            <li>Weekly investment & trade signals</li>
                            <li>Video tutorials & instructions</li>
                            <li>More to be be included</li>
                        </ul>
                        <!-- End Table List -->
                        <!-- Table Bottom -->
                        <div class="button">
                            <a class="btn" href="https://launchpass.com/choplife-gang/member">Start your 1 week free trial</a>
                        </div>
                        <!-- End Table Bottom -->
                    </div>
                    <!-- End Single Table-->
                </div>
            </div>
        </div>
    </section>
    <!--/ End Pricing Table -->
</template>

<script>
export default {
    name: 'pricingComponent'
}
</script>

<style>
    section.pricing-table {
        background: #fff;
    }
</style>