<template>
  <div class="container">Something about contact</div>
</template>

<script>
export default {
  name: 'Contact'
}
</script>

<style>

</style>