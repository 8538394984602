import { createApp } from 'vue'
import {createRouter, createWebHistory} from 'vue-router';

import App from './App.vue'
import Home from './pages/home.vue';
import About from './pages/about.vue';
import Contact from './pages/contact.vue';

const routes = [
    {
        path: '/',
        component: Home
    },
    {
        path: '/about',
        component: About
    },
    {
        path: '/contact',
        component: Contact
    }
]

const Router = createRouter({
    history: createWebHistory(),
    routes
});

const Site = createApp(App);

Site.use(Router);
Site.mount('#app')
