<template>
  <!--====== INTRO VIDEO PART START ======-->

    <section class="intro-video-area overlay section">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="section-title white-text">
                        <span class="wow fadeInDown" data-wow-delay=".2s">Master your destiny</span>
                        <h2 class="wow fadeInUp" data-wow-delay=".4s">Ready to change your perspective?</h2>
                        <p class="wow fadeInUp" data-wow-delay=".6s">Bantu Investments, our goal is to help people acquire the necessary skills to take care of their families and friends.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div class="intro-video-play">
                        <div class="row justify-content-center">
                            <div class="col-lg-10 col-12">
                                <div class="button wow fadeInUp" data-wow-delay="1.1s">
                                    <a href="https://launchpass.com/choplife-gang/member" class="btn">Start your 1 week free trial</a>
                                </div>
                                <!-- <div class="play-thumb wow zoomIn" data-wow-delay=".2s">
                                    <a href="https://www.youtube.com/watch?v=r44RKWyfcFw&fbclid=IwAR21beSJORalzmzokxDRcGfkZA1AtRTE__l5N4r09HcGS5Y6vOluyouM9EM"
                                        class="glightbox video"><i class="lni lni-play"></i></a>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div> <!-- row -->
        </div>
    </section>

    <!--====== INTRO VIDEO PART ENDS ======-->
</template>

<script>
export default {
    name: 'introVideoComponent'
}
</script>

<style>

</style>