<template>
  <!-- Start Service Area -->
    <section id="services" class="services section">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="section-title ">
                        <span class="wow fadeInDown" data-wow-delay=".2s">What you get</span>
                        <h2 class="wow fadeInUp" data-wow-delay=".4s">We Offer</h2>
                        <p class="wow fadeInUp" data-wow-delay=".6s">Our community is here to help you acheive your goals</p>
                    </div>
                </div>
            </div>
            <div class="single-head">
                <img class="service-patern wow fadeInLeft" data-wow-delay=".5s"
                    src="assets/images/service/service-patern.png" alt="#">
                <div class="row justify-content-center">
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="single-service wow fadeInUp" data-wow-delay=".8s">
                            <h3><a href="service-single.html">Investing & Trading</a></h3>
                            <div class="icon">
                                <i class="lni lni-graph"></i>
                            </div>
                            <p>With my <strong>80/20</strong> rule, I aim to help my community build long term portfolios while also allowing them to take advantage of short term market movements</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="single-service wow fadeInUp" data-wow-delay=".8s">
                            <h3><a href="service-single.html">Financial Planning</a></h3>
                            <div class="icon">
                                <i class="lni lni-dollar"></i>
                            </div>
                            <p>It's not always about how much money you make, but rather how much money you keep. I strive to help people do more with less. Sometimes you do have to make more and We will help with that too.</p>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="single-service wow fadeInUp" data-wow-delay=".8s">
                            <h3><a href="service-single.html">Community</a></h3>
                            <div class="icon">
                                <i class="lni lni-users"></i>
                            </div>
                            <p>One is only as strong as his/her community. That's why I beleive building a strong community that can encourage you and hold you accountable is perhaps one of the most valuable things in anyone's life.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- /End Services Area -->
</template>

<script>
export default {
    name: 'servicesComponent'
}
</script>

<style>

</style>