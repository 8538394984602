<template>
  <div>
    <nav-component />
    <main-hero-component />
    <services-component />
    <intro-video-component />
    <about-component />
    <pricing-component />
    <faq-component />
    <footer-component />
  </div>
</template>

<script>
import navComponent from '../components/navComponent.vue';
import mainHeroComponent from '../components/mainHeroComponent.vue';
import servicesComponent from '../components/servicesComponent.vue';
import aboutComponent from '../components/aboutComponent.vue';
//import teamComponent from '../components/teamComponent.vue';
import IntroVideoComponent from '../components/introVideoComponent.vue';
import pricingComponent from '../components/pricingComponent.vue';
import faqComponent from '../components/faqComponent.vue';
//import subscribeComponent from '../components/subscribeComponent.vue';
import footerComponent from '../components/footerComponent.vue';


export default {
  name: 'Home',
  components: {
    mainHeroComponent,
    navComponent,
    servicesComponent,
    aboutComponent,
    //teamComponent,
    IntroVideoComponent,
    pricingComponent,
    faqComponent,
    //subscribeComponent,
    footerComponent
  }
}
</script>

<style>

</style>,
    IntroVideoComponent